<template>
  <page-header-layout class='settlement-detail-container' :style='`height:calc(${style.height}px - 130px);`'>
    <div class='main-page-content' :style='`height: calc(100% - 40px);`'>
      <template>
        <div class='model-handel'>
          <span class='title'>项目概况<i class='el-icon-refresh i-handel' @click='detail' /></span>
          <detail-share ref='share' />
        </div>
        <el-descriptions v-loading='loading.basic' element-loading-text='拼命加载中' direction='vertical' :column='10'
                         border>
          <el-descriptions-item label='项目发布日期'>
            {{ $utils.parseTime(settlementInfo.publish_time, '{y}/{m}/{d}') }}
          </el-descriptions-item>
          <el-descriptions-item label='项目名称'>{{ settlementInfo.proj_name || '-' }}</el-descriptions-item>
          <el-descriptions-item label='品牌'>{{ settlementInfo.brand || '-' }}</el-descriptions-item>
          <el-descriptions-item label='产品/内容'>{{ settlementInfo.product || '-' }}</el-descriptions-item>
          <el-descriptions-item label='博主人数'>{{ settlementInfo.kol_count || '-' }}</el-descriptions-item>
          <el-descriptions-item label='项目总金额'>{{ $utils.numberFormat(settlementInfo.total_amount, 2) }}
          </el-descriptions-item>
          <el-descriptions-item label='应付款总金额'>{{ $utils.numberFormat(settlementInfo.sum_total_amount, 2) }}
          </el-descriptions-item>
          <el-descriptions-item label='信息收集进度'>
            {{ settlementInfo.custom_percent_register || '-' }}
          </el-descriptions-item>
          <el-descriptions-item label='发布进度'>
            {{ settlementInfo.custom_percent_publish || '-' }}
          </el-descriptions-item>
          <el-descriptions-item label='打款进度'>
            {{ settlementInfo.custom_percent_pay || '-' }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
      <div class='model' :style='`margin-top: 20px;height: calc(${style.clientHeight}px - 276px)`'>
        <div class='model-handel'>
          <div class='title' style='width: 320px;'>
            进度表
            <i class='el-icon-refresh i-handel' @click='getTableData'/>
            <el-input placeholder='搜索博主昵称' v-model='formQuery.kol_nickname' class='search-nickname'
                      clearable style='width: 70%;margin-left: 20px;'>
              <el-button slot='append' icon='el-icon-search' @click='getTableData'/>
            </el-input>
          </div>
          <div>
            <el-button v-if="userPermissions.indexOf('settle_proj_detail_add') > -1" type='primary'
                       icon='el-icon-tickets' @click='checkMatch'>博主信息
            </el-button>
            <el-button v-if="userPermissions.indexOf('settle_proj_detail_add') > -1" type='primary'
                       icon='el-icon-plus' @click='handelAddArtist'>添加博主资料
            </el-button>
            <el-button v-if="userPermissions.indexOf('settle_proj_import') > -1"
                       icon='el-icon-download' @click='handelDownload("/settle_proj_detail/download")'>下载模板
            </el-button>
            <upload-settle v-if="userPermissions.indexOf('settle_proj_import') > -1" :settlement-id='settlementId'
                           @success='init'/>
          </div>
        </div>
        <el-table ref='tablePlan' v-loading='loading.table' element-loading-text='拼命加载中' :data='tableData' border
                  @row-contextmenu='handelRightClick'
                  style='width: 100%' :height='`calc(${style.clientHeight}px - 435px)`'>
          <el-table-column type='selection' :selectable='selectable' width='50' />
          <el-table-column label='序号' width='50'>
            <template slot-scope='{$index}'>{{ (formQuery.current_page - 1) * formQuery.page_size + ($index + 1) }}
            </template>
          </el-table-column>
          <el-table-column label='审核状态' width='70'>
            <template slot-scope='{row}'>
              <span v-if='MapStatus[row.status]&&MapStatusShow[MapStatus[row.status]]'
                    :style='`color:${MapStatusShow[MapStatus[row.status]].color}`'>
                {{ MapStatusShow[MapStatus[row.status]].label }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop='kol_nickname' label='博主昵称' width='130' />
          <el-table-column prop='pay_amount' label='应付款' width='70' align='right'>
            <template slot-scope='{row}'>{{ $utils.numberFormat(row.pay_amount, 2) }}</template>
          </el-table-column>
          <el-table-column prop='month' label='月份' width='70' align='center' />
          <el-table-column prop='platform' label='平台' width='70' align='center' show-overflow-tooltip />
          <el-table-column prop='organ_name' label='所属机构' width='180' show-overflow-tooltip />
          <el-table-column prop='company' label='公司全称' width='180' show-overflow-tooltip />
          <el-table-column prop='name' label='姓名(*)' width='100' />
          <el-table-column label='手机号码(*)' width='100'>
            <template slot-scope='{row}'>{{ row.mobile|phone }}</template>
          </el-table-column>
          <el-table-column label='身份证(*)' width='180'>
            <template slot-scope='{row}'>{{ row.id_card|id_card }}</template>
          </el-table-column>
          <el-table-column label='收款账号' width='180'>
            <template slot-scope='{row}'>{{ row.pay_account|bank_no }}</template>
          </el-table-column>
          <el-table-column prop='pay_bank' label='收款银行(具体到支行)' min-width='130' show-overflow-tooltip />
          <el-table-column prop='publish_type' label='发布形式' width='85' show-overflow-tooltip />
          <el-table-column label='发布日期' width='100'>
            <template slot-scope='{row:{publish_date}}'> {{ $utils.parseTime(publish_date, '{y}/{m}/{d}') }}</template>
          </el-table-column>
          <el-table-column label='发布链接' width='180' show-overflow-tooltip>
            <template slot-scope='{row:{publish_link}}'>
              <i v-if='publish_link' class='el-icon-document-copy i-handel' style='margin:0 2px 0 0;'
                 @click='handelCopy(publish_link)' />
              {{ publish_link || '-' }}
            </template>
          </el-table-column>
          <el-table-column prop='koc_cost_price' label='KOC成本价' width='70' align='right'>
            <template slot-scope='{row}'>{{ $utils.numberFormat(row.koc_cost_price, 2) }}</template>
          </el-table-column>
          <el-table-column prop='sale_price' label='对外价(含税)' width='70' align='right'>
            <template slot-scope='{row}'>{{ $utils.numberFormat(row.sale_price, 2) }}</template>
          </el-table-column>
          <el-table-column prop='kol_remark' label='备注' width='100' show-overflow-tooltip />
          <el-table-column label='打款情况' width='70'>
            <template slot-scope='{row:{pay_status}}'>
              <span v-if='MapPayStatus[pay_status]&&MapPayStatusShow[MapPayStatus[pay_status]]'
                    :style='`color:${MapPayStatusShow[MapPayStatus[pay_status]].color}`'>
                {{ MapPayStatusShow[MapPayStatus[pay_status]].label }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop='order_no' label='订单号' width='120' show-overflow-tooltip>
            <template slot-scope='{row:{order_no}}'>
              <i v-if='order_no' class='el-icon-document-copy i-handel' style='margin:0 2px 0 0;'
                 @click='handelCopy(order_no)' />
              {{ order_no || '-' }}
            </template>
          </el-table-column>
          <el-table-column label='微信用户' width='150' show-overflow-tooltip>
            <template slot-scope='{row:{wx_user,id}}'>
              <template v-if='wx_user'>
                <div style='display: flex;align-items: center;'>
                  <span style='width: calc(100% - 30px);overflow: hidden;white-space: nowrap;text-overflow: ellipsis;'>
                    {{ wx_user.nickname }}
                  </span>
                  <el-popconfirm v-if="userPermissions.indexOf('settle_proj_unbind') > -1"
                                 :title='`确定解绑【${wx_user.nickname}】的微信用户吗？`'
                                 @confirm='handelUnbindWx(id)'>
                    <template #reference>
                      <el-button type='text'>解绑</el-button>
                    </template>
                  </el-popconfirm>
                </div>
              </template>
              <span v-else>{{ '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column label='操作' width='180' fixed='right' align='center'>
            <template slot-scope='{row}'>
              <el-button v-if="userPermissions.indexOf('settle_proj_detail_up') > -1" type='text'
                         icon='el-icon-edit' @click='handelEditPlan(row)'>修改
              </el-button>
              <el-button v-if="!(row.wx_user)&&userPermissions.indexOf('settle_proj_detail_up') > -1" type='text'
                         icon='el-icon-s-help' @click='handelMatch(row)'>匹配
              </el-button>
              <el-popconfirm v-if="userPermissions.indexOf('settle_proj_detail_del') > -1" style='margin-left: 10px;'
                             :title='`确定删除【${row.kol_nickname}】的记录吗？`' @confirm='handelDeletePlan([row.id])'>
                <template #reference>
                  <el-button type='text' icon='el-icon-delete'>删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class='footer-handel'>
          <div>
            <el-button v-if="userPermissions.indexOf('settle_proj_detail_check') > -1" type='primary'
                       icon='el-icon-finished' @click='handelPayDone'>打款完成
            </el-button>
            <el-button v-if="userPermissions.indexOf('settle_proj_export') > -1" type='primary'
                       icon='el-icon-download' @click='handelDownload("/settle_proj_detail/export")'>下载进度表
            </el-button>
            <el-button v-if="userPermissions.indexOf('settle_proj_detail_del') > -1"
                       icon='el-icon-delete' @click='handelBatchDeletePlan'>批量删除
            </el-button>
          </div>
          <Pagination :limit.sync='formQuery.page_size' :page.sync='formQuery.current_page' :total='formQuery.total'
                      @pagination='getTableData' />
        </div>
        <detail-log v-if="userPermissions.indexOf('settle_proj_log') > -1" ref='log' :settlement-id='settlementId'
                    @collapse='callbackCollapse' />
      </div>
    </div>
    <dialog-detail-save :show.sync='dialogSavePlan' :settlement-id='settlementId' :obj='settlementPlanCurrentRow'
                        @success='init' />
    <detail-artist-match :dialogMatch='dialogMatch' :show.sync='dialogMatchPlan' :obj='settlementPlanCurrentRow' @success='init' />
    <detail-right-menu ref='rightMenu' @success='init' />
  </page-header-layout>
</template>

<script>
import FileSaver from 'file-saver'
import PageHeaderLayout from '@/layouts/PageHeaderLayout.vue'
import UploadSettle from './components/import-detail.vue'
import DialogDetailSave from './components/dialog-detail-save.vue'
import DetailLog from './components/detail-log.vue'
import { mapGetters } from 'vuex'
import DetailShare from '@/pages/settlement/components/detail-share.vue'
import DetailRightMenu from '@/pages/settlement/components/detail-right-menu.vue'
import DetailArtistMatch from './components/detail-match.vue'

const ModelDetail = {
  'id': 0,
  'proj_name': null,
  'brand': null,
  'product': null,
  'kol_count': 0,
  'total_amount': null,
  'collect_process': 0,
  'publish_process': 0,
  'payment_process': 0,
  'publisher_id': null,
  'publisher_name': null,
  'publish_time': null,
  'status': null,
  'custom_percent_register': null,
  'custom_percent_publish': null,
  'custom_percent_pay': null,
  'share_url': null,
  'created_at': null,
  'updated_at': null
}
const MapStatus = {
  1: 'WAIT',
  2: 'DONE'
}
const MapStatusShow = {
  WAIT: { label: '未匹配', value: 1, color: 'grey' },
  DONE: { label: '已匹配', value: 2, color: '#FF3176' }
}
const MapPayStatus = {
  1: 'WAIT',
  2: 'DONE'
}
const MapPayStatusShow = {
  WAIT: { label: '未打款', value: 1, color: 'grey' },
  DONE: { label: '已打款', value: 2, color: '#FF3176' }
}
export default {
  name: 'detail',
  components: {
    DetailArtistMatch,
    DetailRightMenu,
    DetailShare,
    DetailLog,
    DialogDetailSave,
    UploadSettle,
    PageHeaderLayout
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  filters: {
    phone(value) {
      if (!value) return
      return value.replace(/^(\d{3})\d{4}(\d+)/, '$1****$2')
    },
    id_card(value) {
      if (!value) return
      if (value.length - 10 > 0) {
        const char = new Array(value.length - 10).join('*')
        value = value.replace(/^(.{6})(?:\d+)(.{4})$/, '$1' + char + '$2')
      }
      return value.replace(/(.{4})(?=.)/g, '$1 ')
    },
    bank_no(value) {
      if (!value) return
      const end = value.slice(-4)
      if (value.length - 3 > 0) {
        value = new Array(value.length - 3).join('*') + end
      }
      return value.replace(/(.{4})(?=.)/g, '$1 ')
    }
  },
  data() {
    return {
      MapStatus,
      MapPayStatus,
      MapStatusShow,
      MapPayStatusShow,
      style: {
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
        clientHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      },
      loading: {
        basic: false,
        table: false
      },
      dialogSavePlan: false,
      dialogMatchPlan: false,
      settlementId: 0,
      settlementInfo: { ...{}, ...ModelDetail },
      settlementPlanCurrentRow: false,
      formQuery: {
        kol_nickname: null,
        total: 0,
        page_size: 15,
        current_page: 1
      },
      tableData: [],
      dialogMatch:true
    }
  },
  mounted() {
    this.settlementId = parseInt(this.$route.params.settlement_id)
    this.init()
  },
  methods: {
    async init() {
      await this.detail()
      await this.getTableData()
      this.$refs.log && await this.$refs.log.getList()
    },
    callbackCollapse(open) {
      const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      this.style.height = open ? height + 480 : height
    },
    /**
     * 基础信息
     * @returns {Promise<void>}
     */
    async detail() {
      this.loading.basic = true
      let { info } = await this.$api.detailSettlement({ id: this.settlementId })
      this.settlementInfo = info
      this.settlementInfo.custom_percent_register = this.calcPercent(info.register_count, info.kol_count, 1)
      this.settlementInfo.custom_percent_publish = this.calcPercent(info.publish_count, info.kol_count, 1)
      this.settlementInfo.custom_percent_pay = this.calcPercent(info.pay_count, info.kol_count, 1)
      this.loading.basic = false
      this.$refs.share && this.$refs.share.init(info)
    },
    /**
     * 明细table
     * @returns {Promise<void>}
     */
    async getTableData() {
      const params = { ...{}, ...this.formQuery }
      params.project_id = this.settlementId
      if (!params.kol_nickname) {
        delete params.kol_nickname
      }
      delete params.total
      this.loading.table = true
      const { list, page_info } = await this.$api.settlementPlanList(params)
      this.loading.table = false
      this.tableData = list
      this.formQuery.total = page_info.total || 0
    },
    selectable() {
      return true
      // return parseInt(row.status) === MapStatusShow.WAIT.value
    },
    /**
     * 删除行明细
     * @param ids
     * @returns {Promise<void>}
     */
    async handelDeletePlan(ids) {
      await this.$api.settlementPlanDelete({ ids })
      this.$message.success('删除成功')
      await this.init()
    },
    async handelUnbindWx(detail_id) {
      await this.$api.settlementPlanUnbind({ detail_id })
      this.$message.success('解绑成功')
      await this.init()
    },
    /**
     * 批量操作前
     * @returns {{dom: VNode, ids: *[]}|boolean}
     */
    beforeBatchHandle() {
      const selection = this.$refs.tablePlan.selection
      if (!selection.length) {
        this.$message.warning('请选择数据！')
        return false
      }

      const h = this.$createElement
      const dom = []
      const ids = selection.map((_, i) => {
        dom.push(h('span', { class: { artist: true } }, (i + 1) + '. ' + _.kol_nickname))
        return _.id
      })
      const subData = selection.map((_) => {
        return { detail_id:_.id, project_id:_.project_id,register_id:_.register_id}
      })
      // return { detail_id:_.id, project_id:_.project_id,register_id:_.register_id}

      const domMessage = h('div', { style: { maxHeight: '200px', overflowY: 'auto' } }, dom)
      return { dom: domMessage, ids,subData }
    },
    /**
     * 批量删除
     */
    handelBatchDeletePlan() {
      const { dom, ids } = this.beforeBatchHandle()
      if (!ids || !dom) {
        return
      }
      this.$confirm(
        '提示',
        {
          title: '确定删除以下博主的打款记录吗？',
          message: dom,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }
      )
        .then(async () => {
          await this.handelDeletePlan(ids)
        })
    },
    handelEditPlan(row) {
      this.dialogSavePlan = true
      this.settlementPlanCurrentRow = row
    },
    handelMatch(row) {
      this.dialogMatch=true
      this.dialogMatchPlan = true
      this.settlementPlanCurrentRow = row
      console.log(this.settlementPlanCurrentRow)
    },
    checkMatch(){
      this.dialogMatch=false
      this.dialogMatchPlan = true
      let {id} =this.settlementInfo
      this.settlementPlanCurrentRow={project_id:id}
      // console.log(id)
      // console.log(this.settlementPlanCurrentRow)
      // this.settlementPlanCurrentRow.project_id=this.settlementId.id
    },
    /**
     * 添加博主资料按钮
     */
    handelAddArtist() {
      this.dialogSavePlan = true
      this.settlementPlanCurrentRow = false
    },
    /**
     * 打款完成（批量）
     */

    handelPayDone() {
      const { dom, subData } = this.beforeBatchHandle()
      if (!subData || !dom) {
        return
      }
      console.log(subData)
      this.$confirm(
        '提示',
        {
          title: '确定标记完成以下博主的打款记录吗？',
          message: dom,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }
      )
        .then(async () => {
          await this.$api.settlementPlanStatus({ ids:subData })
          this.$message.success('操作成功！')
          await this.init()
        })
    },
    /**
     * 下载模板
     * @returns {Promise<void>}
     */
    async handelDownload(path) {
      try {
        const isFileSaverSupported = !!new Blob
        if (isFileSaverSupported) {
          let response = await this.$api.downLoadFile({ project_ids: [this.settlementId] }, path)
          let data = response.data
          let contentType = response.headers['content-type']
          let blob = new Blob([data], { type: contentType })

          const name = path === '/settle_proj_detail/download' ? '项目结款导入模板' : this.settlementInfo.proj_name + '进度表'
          FileSaver.saveAs(blob, decodeURI(name + '.xlsx'), { autoBom: true })
        } else {
          this.$message.warning('当前浏览器不支持导出文件')
        }
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    handelCopy(text) {
      const value = text
      const oInput = document.createElement('input')
      oInput.value = value
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({ message: '复制成功', type: 'success' })
      oInput.remove()
    },
    calcPercent(dividend, divisor) {
      if (!dividend || !divisor) {
        return '-'
      }
      return (dividend * 100 / divisor).toFixed(2) + '%'
    },
    handelRightClick(row, column, event) {
      this.$refs.rightMenu.handelRightClick(row, column, event)
    }
  }
}
</script>

<style lang='scss'>
.settlement-detail-container {
  @font-face {
    font-family: 'FjallaOne-Regular';
    src: url("~@/assets/font/FjallaOne-Regular.ttf");
  }
  font-family: FjallaOne-Regular, DINPro Medium, sans-serif !important;
  height: 100%;

  .page-content {
    min-height: unset;
    height: 100%;

    .main-page-content {
      min-height: unset;

    }
  }

  .el-table__cell {
    .el-button--text {
      padding: 0;
    }
  }

  .i-handel {
    cursor: pointer;
    color: #FF3176;
    margin-left: 4px;
  }

  .el-table__cell {
    padding: 5px 0;

    .cell {
      height: 20px !important;
      line-height: 20px !important;
    }
  }

  .model-handel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .title {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .footer-handel {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .search-nickname {
    .el-input__inner {
      border-radius: 16px 0 0 16px;
    }

    .el-input-group__append {
      border-radius: 0 16px 16px 0;
      background: transparent;
      border-left: 0;
      color: #FF3176;
      font-weight: bolder;
      font-size: 14px;
    }
  }
}

</style>
<style lang='scss' scoped>
.artist {
  display: inline-block;
  border: 1px solid #f75087;
  color: #f75087;
  border-radius: 19px;
  padding: 0 6px;
  margin: 2px;
  line-height: 1.6;
  width: 28%;
  float: left;
}
</style>
