<template>
  <div id='menu' class='menu'>
    <ul>
      <li @click='handelSureCancel'>
        <i class='el-icon-refresh-right' />撤销打款
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const MapPayStatusShow = {
  WAIT: { label: '未打款', value: 1, color: 'grey' },
  DONE: { label: '已打款', value: 2, color: '#FF3176' }
}
export default {
  name: 'detail-right-menu',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      row: {}
    }
  },
  methods: {
    handelSureCancel() {
      this.$confirm(`确认撤销「${this.row.kol_nickname}」的「 打款成功 」标记?`, '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(async () => {
          await this.$api.settlementPlanUnPay({ id: this.row.id })
          this.$message.success('操作成功！')
          this.$emit('success')
        })
    },
    handelRightClick(row, column, event) {
      let menu = document.querySelector('#menu')
      menu.style.display = 'none'
      if (this.userPermissions.indexOf('settle_proj_unpay') === -1) {
        return
      }
      if (row.pay_status !== MapPayStatusShow.DONE.value) {
        return
      }
      this.row = row
      //阻止元素发生默认的行为
      event.preventDefault()
      // 根据事件对象中鼠标点击的位置，进行定位
      menu.style.left = event.clientX + 10 + 'px'
      menu.style.top = event.clientY + 'px'
      // 改变自定义菜单的隐藏与显示
      menu.style.display = 'block'
      menu.style.zIndex = 1000
      document.addEventListener('click', this.closeMenu)
    },
    closeMenu() {
      let menu = document.querySelector('#menu')
      menu.style.display = 'none'
      document.removeEventListener('click', this.closeMenu)
    }
  }
}
</script>
<style lang='scss' scoped>
.menu {
  display: none;
  position: absolute;

  ul {
    height: auto;
    width: auto;
    font-size: 14px;
    border-radius: 3px;
    background-color: #fff;
    color: #ff3176;
    list-style: none;
    text-align: center;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%);

    li {
      //width: 140px;
      padding: 0 10px;
      height: 35px;
      line-height: 35px;
      cursor: pointer;
      border: 1px solid #2b3b4e;
      border-radius: 3px;

      i {
        margin-right: 4px;
      }

      &:hover {
        color: #fff;
        background: #ff3176;
        border: unset;
      }
    }
  }
}
</style>
