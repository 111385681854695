<template>
  <el-dialog :visible.sync='dialogSavePlan' :title='`${obj?"编辑":"添加"}博主资料`' custom-class='dialog-plan'
             width='750px' center>
    <div :class='`${obj?"readonly":""}`' style='height: 600px;'>
      <div class='item' :class='`${editModule===MapEditModule.BASIC?"editing":""}`'>
        <el-form ref='formBasic' :model='formStore' :rules='rules' label-position='right' label-width='110px'
                 :disabled='(!!obj&&editModule!==MapEditModule.BASIC)'>
          <el-divider content-position='left'>
            基本信息 <i class='el-icon-edit-outline i-handel' @click='editModule=MapEditModule.BASIC' />
          </el-divider>
          <el-form-item label='博主昵称' prop='kol_nickname'>
            <el-input v-model='formStore.kol_nickname' placeholder='请输入博主昵称' />
          </el-form-item>
          <el-form-item label='应付款' prop='pay_amount'>
            <el-input :value='formStore.pay_amount' @input="changeValueOnlyNumber($event, 'pay_amount')"
                      placeholder='请输入应付款' />
          </el-form-item>
          <el-form-item label='平台' prop='platform'>
            <el-select v-model='formStore.platform' placeholder='请选择平台' style='width: 100%'>
              <el-option v-for='item in optionsPlatform' :key='item.name' :label='item.name' :value='item.name' />
            </el-select>
          </el-form-item>
          <el-form-item label='所属机构' prop='organ_name'>
            <el-input v-model='formStore.organ_name' :placeholder='`${obj?"未填写":"请输入所属机构"}`' />
          </el-form-item>
          <el-form-item label='公司全称' prop='company'>
            <el-input v-model='formStore.company' :placeholder='`${obj?"未填写":"请输入公司全称"}`' />
          </el-form-item>
          <el-button v-if='obj&&editModule===MapEditModule.BASIC' type='primary' size='mini' @click='handelSure'>保存
          </el-button>
        </el-form>
      </div>
      <div class='item' :class='`${editModule===MapEditModule.FINAL?"editing":""}`'>
        <el-form label-position='right' label-width='110px' :disabled='(!!obj&&editModule!==MapEditModule.FINAL)'>
          <el-divider content-position='left'>
            财务信息 <i class='el-icon-edit-outline i-handel' @click='editModule=MapEditModule.FINAL' />
          </el-divider>
          <el-form-item label='KOC成本价' prop='koc_cost_price'>
            <el-input :value='formStore.koc_cost_price' @input="changeValueOnlyNumber($event, 'koc_cost_price')"
                      :placeholder='`${obj?"未填写":"请输入KOC成本价"}`' />
          </el-form-item>
          <el-form-item label='对外价(含税)' prop='sale_price'>
            <el-input :value='formStore.sale_price' @input="changeValueOnlyNumber($event, 'sale_price')"
                      :placeholder='`${obj?"未填写":"请输入对外价(含税)"}`' />
          </el-form-item>
          <el-form-item label='打款情况' prop='pay_status'>
            <el-select v-model='formStore.pay_status' :placeholder='`${obj?"未填写":"请选择打款情况"}`'
                       style='width: 100%'>
              <el-option v-for='item in MapStatusShow' :key='item.value' :label='item.label' :value='item.value' />
            </el-select>
          </el-form-item>
          <el-form-item label='订单号' prop='order_no'>
            <el-input v-model='formStore.order_no' :placeholder='`${obj?"未填写":"请输入订单号"}`' />
          </el-form-item>
          <el-form-item label='备注' prop='remark'>
            <el-input v-model='formStore.remark' type='textarea' :autosize='{ minRows: 2, maxRows: 2}'
                      :placeholder='`${obj?"未填写":"请输入备注"}`' />
          </el-form-item>
          <el-button v-if='obj&&editModule===MapEditModule.FINAL' type='primary' size='mini' @click='handelSure'>保存
          </el-button>
        </el-form>
      </div>
      <div class='item' :class='`${editModule===MapEditModule.PAY?"editing":""}`'>
        <el-form ref='formPay' :model='formStore' :rules='rules' label-position='right' label-width='110px'
                 :disabled='(!!obj&&editModule!==MapEditModule.PAY)'>
          <el-divider content-position='left'>
            打款信息 <i class='el-icon-edit-outline i-handel' @click='editModule=MapEditModule.PAY' />
          </el-divider>
          <el-form-item label='姓名' prop='name'>
            <el-input v-model='formStore.name' :placeholder='`${obj?"未填写":"请输入姓名"}`' />
          </el-form-item>
          <el-form-item label='手机号码' prop='mobile'>
            <el-input v-model='formStore.mobile' :placeholder='`${obj?"未填写":"请输入手机号码"}`' />
          </el-form-item>
          <el-form-item label='身份证' prop='id_card'>
            <el-input v-model='formStore.id_card' :placeholder='`${obj?"未填写":"请输入身份证"}`' />
          </el-form-item>
          <el-form-item label='收款账号' prop='pay_account'>
            <el-input v-model='formStore.pay_account' :placeholder='`${obj?"未填写":"请输入收款账号"}`' />
          </el-form-item>
          <el-form-item prop='pay_bank' class='form-label-bank'>
            <div slot='label'>
              收款银行
              <div style='font-size: 12px;color: #7a8491'>具体到支行</div>
            </div>
            <el-input v-model='formStore.pay_bank' :placeholder='`${obj?"未填写":"请输入收款银行（具体到支行）"}`' />
          </el-form-item>

          <el-button v-if='obj&&editModule===MapEditModule.PAY' type='primary' size='mini' @click='handelSure'>保存
          </el-button>
        </el-form>
      </div>
      <div class='item' :class='`${editModule===MapEditModule.PUBLISH?"editing":""}`'>
        <el-form label-position='right' label-width='110px' :disabled='(!!obj&&editModule!==MapEditModule.PUBLISH)'>
          <el-divider content-position='left'>
            博主发布信息 <i class='el-icon-edit-outline i-handel' @click='editModule=MapEditModule.PUBLISH' />
          </el-divider>
          <el-form-item label='发布形式' prop='publish_type'>
            <el-select v-model='formStore.publish_type' :placeholder='`${obj?"未填写":"请选择发布形式"}`'
                       style='width: 100%'>
              <el-option v-for='item in MapPublish' :key='item' :label='item' :value='item' />
            </el-select>
          </el-form-item>
          <el-form-item label='发布日期' prop='publish_date'>
            <el-date-picker v-model='formStore.publish_date' type='date' value-format='yyyy-MM-dd'
                            :placeholder='`${obj?"未填写":"请选择发布日期"}`' style='width: 100%' />
          </el-form-item>
          <el-form-item label='发布链接' prop='publish_link'>
            <el-input v-model='formStore.publish_link' :placeholder='`${obj?"未填写":"请输入发布链接"}`' />
          </el-form-item>
          <el-form-item label='博主备注' prop='kol_remark'>
            <el-input v-model='formStore.kol_remark' type='textarea' :autosize='{ minRows: 4, maxRows: 4}'
                      :placeholder='`${obj?"未填写":"请输入博主备注"}`' />
          </el-form-item>
          <el-button v-if='obj&&editModule===MapEditModule.PUBLISH' type='primary' size='mini' @click='handelSure'>保存
          </el-button>
        </el-form>
      </div>
    </div>
    <span v-if='!obj' slot='footer' class='dialog-footer'>
        <el-button @click='dialogSavePlan = false'>取 消</el-button>
        <el-button type='primary' @click='handelSure'>确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
const ModelFormStore = {
  kol_nickname: null,
  pay_amount: null,
  platform: null,
  organ_name: null,
  company: null,
  koc_cost_price: null,
  sale_price: null,
  status: null,
  order_no: null,
  remark: null,
  name: null,
  mobile: null,
  id_card: null,
  pay_account: null,
  pay_bank: null,
  pay_status: null,
  publish_type: null,
  publish_date: null,
  publish_link: null,
  kol_remark: null
}
const MapStatusShow = {
  WAIT: { label: '未打款', value: 1, color: 'grey' },
  DONE: { label: '已打款', value: 2, color: '#FF3176' }
}
const MapEditModule = {
  BASIC: 'BASIC',
  FINAL: 'FINAL',
  PAY: 'PAY',
  PUBLISH: 'PUBLISH'
}
const MapEditModuleLabel = {
  BASIC: '基础信息',
  FINAL: '财务信息',
  PAY: '打款信息',
  PUBLISH: '博主发布信息'
}
const MapPublish = ['图文', '视频', '图文/视频', '其他']

const validateIdCard = (rule, value, callback) => {
  const regular = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/
  if (value && regular.test(value) !== true) {
    callback(new Error('身份证格式错误！'))
  } else {
    callback()
  }
}

const rules = {
  kol_nickname: [
    { required: true, message: '请输入博主昵称', trigger: 'blur' },
    { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
  ],
  pay_amount: [{ required: true, message: '请填写应付款', trigger: 'blur' }],
  id_card: [{ trigger: 'blur', validator: validateIdCard }]
}
export default {
  name: 'dialog-detail-save',
  props: {
    settlementId: {
      type: [Number, String],
      required: true
    },
    obj: {
      type: [Object, Boolean],
      default: false
    },
    show: {
      type: [Boolean],
      default: false
    }
  },
  watch: {
    show() {
      this.formStore = { ...{}, ...ModelFormStore }
      this.dialogSavePlan = this.show
    },
    dialogSavePlan(_) {
      if (!_) {
        this.$emit('update:show', false)
        this.$emit('success')
      } else {
        this.$nextTick(() => {
          this.$refs.formBasic.clearValidate()
          this.$refs.formPay.clearValidate()
        })
      }
    },
    obj: {
      immediate: true,
      handler: function(_) {
        this.$nextTick(() => {
          if (_) {
            this.formStore = { ...{}, ..._ }
          }
        })
      }
    },
    editModule(_new, _old) {
      if (_old && _new) {
        this.handelSure(_old)
      }
    }
  },
  data() {
    return {
      rules,
      MapEditModule,
      MapStatusShow,
      MapPublish,
      editModule: false,
      dialogSavePlan: false,
      optionsPlatform: [],
      formStore: { ...{}, ...ModelFormStore }
    }
  },
  created() {
    this.dialogSavePlan = this.show
    this.getOptionsPlatform()
  },
  methods: {
    async getOptionsPlatform() {
      const { list } = await this.$api.getPlatformAll({})
      this.optionsPlatform = list || []
    },
    changeValueOnlyNumber(val, virtue) {
      const _val = val.split(',').join('')
      // eslint-disable-next-line no-empty
      if (isNaN(Number(_val))) {
      } else {
        const _indexOf = _val.indexOf('.')
        if (_indexOf >= 0) {
          if (_val.length - 1 - _indexOf <= 2) {
            this.formStore[virtue] = _val
          }
        } else {
          this.formStore[virtue] = _val
        }
      }
    },
    async handelSure(editModule = false) {
      let error = false
      this.$refs.formBasic.validate(async (valid) => {
        error = !valid
      })
      this.$refs.formPay.validate(async (valid) => {
        error = !error ? !valid : error
      })
      if (error) {
        return
      }

      this.formStore.project_id = this.settlementId
      if (this.obj) {
        await this.$api.settlementPlanUpdate(this.formStore)
        const auto = typeof editModule === 'string' ? '自动' : ''
        const textEditModule = typeof editModule === 'string' ? editModule : this.editModule
        this.$message.success(`【${MapEditModuleLabel[textEditModule]}】${auto}保存成功`)
        if (!auto) {
          this.editModule = false
        }
      } else {
        await this.$api.settlementPlanSave(this.formStore)
        this.dialogSavePlan = false
        this.$message.success('操作成功')
      }
      // })
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
<style lang='scss'>
.dialog-plan {
  display: flex;
  display: -ms-flex; /* 兼容IE */
  flex-direction: column;
  -ms-flex-direction: column; /* 兼容IE */
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);

  .el-dialog__body {
    max-height: 100%;
    flex: 1;
    -ms-flex: 1 1 auto; /* 兼容IE */
    overflow-y: auto;
    overflow-x: hidden;
  }

  .el-dialog__wrapper {
    /*隐藏ie和edge中遮罩的滚动条*/
    overflow: hidden;
  }

  .el-divider--horizontal {
    margin-top: 0;
  }

  .readonly {
    .editing {
      box-shadow: 0 6px 12px 0 rgba(0 0 0 / 10%);
    }

    .item:not(.editing) {

      .el-input__inner, .el-textarea__inner {
        border: unset;
      }

      .el-input__inner::placeholder, .el-textarea__inner::placeholder {
        color: #afafaf;
      }

      .el-input.is-disabled .el-input__inner, .el-textarea.is-disabled .el-textarea__inner {
        background-color: #FFF;
        color: #606266;
        cursor: not-allowed;
      }
    }
  }

  .item {
    width: calc((100% - 24px) / 2);
    border: 1px solid #DCDFE6;
    border-top: unset;
    float: left;
    margin-bottom: 20px;
    height: 285px;
    text-align: center;

    .el-form-item {
      width: calc(100% - 20px);
      margin-bottom: 12px;
    }
  }

  .item:nth-child(even) {
    margin-left: 20px;
  }

  .form-label-bank {
    .el-form-item__label {
      line-height: 14px;
    }
  }
}
</style>