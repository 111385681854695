<template>
  <div>
    <el-popover placement='bottom-start' trigger='hover'>
      <div>
        <div class='settlement-detail-share-container'>
          <div class='share' id='posterHtml'>
            <div class='top'>
              <div class='left'>
                <div>发布日期：{{ $utils.parseTime(settlementInfo.publish_time, '{y}/{m}/{d}') }}</div>
                <div>项目名称：{{ settlementInfo.proj_name || '-' }}</div>
              </div>
              <div class='right'><img src='@/assets/avatar.png' /></div>
            </div>
            <div class='bottom'>
              <div class='line' />
              <div class='main'>
                <div style='line-height: 1.5;margin-right: 10px;'>
                  <div>分享人：{{ nickname }}</div>
                  <div>品牌：{{ settlementInfo.brand || '-' }}</div>
                  <div>产品内容：{{ settlementInfo.product || '-' }}</div>
                </div>
                <!-- 二维码 -->
                <div class='qrcode'>
                  <div id='qrcodeImg' />
                </div>
              </div>
              <div class='line' style='margin-top: 10px;margin-bottom: 0;' />
              <div class='tip'>请使用微信APP扫码</div>
            </div>
          </div>
        </div>
        <el-button slot='reference' icon='el-icon-download' style='margin: 10px auto 0 auto;display: block;'
                   @click='handelClick'>下载图片
        </el-button>
      </div>
      <el-button type='primary' slot='reference' icon='el-icon-share'>分享「图片」</el-button>
    </el-popover>
    <el-button type='primary' slot='reference' icon='el-icon-share' style='margin-left: 4px;' @click='handelCopy'>
      分享「链接」
    </el-button>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'

const { nickname } = JSON.parse(localStorage.getItem('user_info'))
export default {
  name: 'detail-share',
  data() {
    return {
      nickname,
      shareUrl: null,
      settlementInfo: {}
    }
  },
  methods: {
    init(info) {
      this.settlementInfo = info
      this.shareUrl = `${this.settlementInfo.share_url}/settlement/${window.btoa(this.settlementInfo.id)}/projectList`
      this.$nextTick(() => {
        this.qrcode()
      })
    },
    handelClick() {
      this.createPoster()
    },
    qrcode() {
      const qrcodeImgEl = document.getElementById('qrcodeImg')
      qrcodeImgEl.innerHTML = ''
      let qrcode = new QRCode(
        qrcodeImgEl,
        {
          width: 90,
          height: 90,
          colorDark: '#000000',
          colorLight: '#ffffff'
        }
      )
      qrcode.makeCode(this.shareUrl)
    },
    createPoster() {
      const _this = this
      // 生成海报
      const domObj = document.getElementById('posterHtml')
      html2canvas(domObj, { dpi: 600 })
        .then(function(canvas) {
          const a = document.createElement('a')
          a.href = canvas.toDataURL('image/png')
          a.download = `${_this.settlementInfo.proj_name}.png`
          a.click()
        })
    },
    handelCopy() {
      const oInput = document.createElement('textarea')

      const url = this.shareUrl
      oInput.value = `【微信】${url}「${this.settlementInfo.proj_name}」${this.settlementInfo.product}\r请使用微信点击链接，登记结款信息。`

      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({ message: '复制成功', type: 'success' })
      oInput.remove()
    }
  }
}
</script>

<style lang='scss'>
.settlement-detail-share-container {
  @font-face {
    font-family: 'FjallaOne-Regular';
    src: url("~@/assets/font/FjallaOne-Regular.ttf");
  }
  font-family: FjallaOne-Regular, DINPro Medium, sans-serif !important;

  .share {
    background: rgba(224, 32, 32, 1);
    border-radius: 10px;
    width: 300px;
    color: #FFFFFF;
    padding: 15px 5px 5px 5px;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 15px;
    padding-right: 15px;
    font-family: FjallaOne-Regular, DINPro Medium, sans-serif !important;

    .left {
      line-height: 1.5;
      margin-right: 10px;
    }

    .right {
      display: flex;

      img {
        border-radius: 5px;
        width: 60px;
      }
    }
  }

  .bottom {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 10px 10px 5px;
    margin-top: 10px;
    color: #656464;

    .line {
      border: 1px dashed #656464;
      margin-bottom: 10px;
    }

    .main {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .tip {
      font-size: 12px;
      text-align: center;
      margin-top: 2px;
      color: #949494;
    }
  }
}
</style>
