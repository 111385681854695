<template>
  <el-dialog :title='dialogMatch?"匹配博主信息":"全部博主信息"' :visible.sync='visible' width='1000px' style="max-height: 1000px;overflow-y: scroll" center custom-class='default-table'>
    <div>
      <el-table :data='tableData' style='width: 100%;' max-height='800' @row-click='rowClick'>
        <el-table-column label='#' width='80' v-if="dialogMatch">
          <template v-slot='scope'>
            <el-radio id='selectGroup' v-model='radio' :label='scope.row.id'>&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column label='微信用户' min-width='120' show-overflow-tooltip>
          <template slot-scope='{row:{register}}'>
            {{ register && register.wx_user && register.wx_user.nickname ? register.wx_user.nickname : '' }}
          </template>
        </el-table-column>
        <el-table-column label='博主昵称' min-width='130'>
          <template slot-scope='{row:{register}}'>
            {{ register && register.kol_nickname ? register.kol_nickname : '' }}
          </template>
        </el-table-column>
        <el-table-column label='姓名(*)' width='100'>
          <template slot-scope='{row:{register}}'>
            {{ register && register.name ? register.name : '' }}
          </template>
        </el-table-column>
        <el-table-column label='是否匹配' width='100'>
          <template slot-scope='{row}'>
            {{ row && row.is_match===1 ? '未匹配' : '已匹配' }}
          </template>
        </el-table-column>
        <el-table-column label='手机号码' width='130'>
          <template slot-scope='{row:{register}}'>
            {{ register && register.mobile ? register.mobile : '' }}
          </template>
        </el-table-column>
        <el-table-column label='提交时间' width='160'>
          <template slot-scope='{row:{created_at}}'> {{ created_at }}</template>
        </el-table-column>
      </el-table>
      <div v-if='radio && dialogMatch' class='tip'>
        当前选择微信用户
        【
        {{
          currentRow && currentRow.register && currentRow.register.wx_user && currentRow.register.wx_user.nickname ? currentRow.register.wx_user.nickname : ''
        }}
        】
        提交的
        【
        {{
          currentRow && currentRow.register && currentRow.register.kol_nickname ? currentRow.register.kol_nickname : ''
        }}
        】
        信息匹配给博主【{{ obj.kol_nickname }}】，核对无误后点击确定保存
      </div>
    </div>
    <span slot='footer' class='dialog-footer' v-if="dialogMatch">
      <el-button @click='visible = false' size='mini'>取 消</el-button>
      <el-button type='primary' @click='handelSure' size='mini'>确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'DetailArtistMatch',
  props: {
    show: {
      type: Boolean,
      default() {
        return false
      }
    },
    obj: {
      type: [Boolean, Object],
      default() {
        return false
      }
    },
    dialogMatch: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  data() {
    return {
      radio: 0,
      visible: this.show,
      currentRow: false,
      tableData: []
    }
  },
  watch: {
    show: async function(v) {
      if (v) {
        this.currentRow = false
        this.radio = 0
        await this.getTableData()
        this.visible = v
      }
    },
    visible: function(v) {
      if (!v) {
        this.$emit('update:show', false)
      }
    }
  },
  methods: {
    async getTableData() {
      let data = { project_id: this.obj.project_id }
      this.dialogMatch? data.is_match=1:''
      this.tableData = await this.$api.settlementForMatch(data)
    },
    /**
     * 确认匹配
     * @returns {Promise<void>}
     */
    async handelSure() {
      if (!this.currentRow) {
        this.$message.warning('请选择要匹配的行数据！')
        return
      }
      const params = {
        register_id: this.currentRow.register_id,
        project_id: this.obj.project_id,
        detail_id: this.obj.id
      }
      this.tableData = await this.$api.settlementDoMatch(params)

      this.$emit('success')
      this.visible = false
      this.$message.success('操作成功！')
    },
    rowClick(row) {
      this.currentRow = row
      this.radio = row.id
    }
  }
}
</script>
<style lang='scss' scoped>
.tip {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px 18px;
  //width: 100%;
  height: 32px;
  border-radius: 9px;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 0, 0, 0.06)), to(rgba(61, 127, 255, 0.09)));
  background-image: linear-gradient(270deg, rgba(255, 0, 0, 0.06), rgba(61, 127, 255, 0.09));
}
</style>
