<template>
  <el-collapse @change='handleChange'>
    <el-collapse-item title='操作日志'>
      <div class='el-table'>
        <el-table :data='dataList' style='width: 60%'>
          <el-table-column label='操作时间' prop='created_at' width='140' />
          <el-table-column label='操作内容' prop='content' show-overflow-tooltip />
        </el-table>
        <Pagination :limit.sync='query.page_size' :page.sync='query.current_page' :total='total'
                    @pagination='getList' style='width: 60%' />
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  name: 'detail-log',
  props: {
    settlementId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      total: 0,
      dataList: [],
      query: {
        project_id: this.settlementId,
        page_size: 10,
        current_page: 1
      }
    }
  },
  watch: {
    settlementId(_) {
      this.query.project_id = _
    }
  },
  methods: {
    handleChange(_) {
      this.$emit('collapse', _.length > 0)
    },
    async getList() {
      const { list, page_info } = await this.$api.settlementPlanLog(this.query)
      this.dataList = list || []
      this.total = page_info.total || 0
    }
  }
}
</script>

<style scoped>

</style>